

@import 'variables.scss';

/***********************************************************************************************
global styles
***********************************************************************************************/

// these classes can be used throughout the application to ensure a constant look and feel.

// global layout
/*-----------------------------------------------------------------------------------*/

@font-face {
    font-family: "Inter";
    src: url("assets/fonts/InterVariableFont.ttf") format("truetype");
    font-display: swap;
}

body, html {
    font-family: "Inter", sans-serif;
    margin: 0;
    font-size: 16px;
}

h1 {
    color: $color-black;
    font-size: $default-h1-font-size;
    hyphens: auto;
}

h2 {
    color: $color-black;
    font-size: $default-h2-font-size;
    text-decoration: none;
    font-weight: 400;
    hyphens: auto;
}

p {
    color: $color-black;
    font-size: $default-p-font-size;
    hyphens: auto;
}

.caption {
    color: $color-black;
    font-size: $default-caption-font-size;
}

.text-transition {
    transition: color 2s ease-in-out;
}

.unselectable {
    -webkit-touch-callout: none; 
    -webkit-user-select: none;   
    -khtml-user-select: none;    
    -moz-user-select: none;      
    -ms-user-select: none;       
    user-select: none;           
}

// media queries
/*-----------------------------------------------------------------------------------*/

@media only screen and (max-width: 375px) {
    h1 {
      font-size: calc($small-h1-font-size * .85)!important;
    }
}

@media only screen and (max-width: 480px) {
    h1 {
      font-size: $small-h1-font-size;
    }

    h2 {
      font-size: $small-h2-font-size;
    }

    p {
      font-size: $small-p-font-size;
    }

    .caption {
        font-size: $small-caption-font-size;
    }

    .default-button {
        height: 32px!important;
    }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
    h1 {
      font-size: $medium-h1-font-size;
    }

    h2 {
      font-size: $medium-h2-font-size;
    }

    p {
        font-size: $medium-p-font-size;
    }

    .caption {
        font-size: $medium-caption-font-size;
    }

    .default-button {
        height: 36px!important;
    }
}

// color classes
/*-----------------------------------------------------------------------------------*/

.color-primary {
    color: $color-primary;
}

.color-white {
    color: white;
}

.clickable-highlight-color {
    color: $color-primary;
    cursor: pointer; 
}

// buttons
/*-----------------------------------------------------------------------------------*/

.default-button {
    background-color: $color-primary;
    border-radius: 2px;
    padding-left: 32px;
    padding-right:32px;
    padding-top: 4px;
    padding-bottom: 4px;
    font-weight: 400;
    font-family: Inter;
    height: 2.5rem!important;
    letter-spacing: 0px;
    
    p {
        color: white;
        margin: 0;
    }

    &.altered {
        p {
            color: $color-black;
        }
    }

    &.circled {
        background-color: $color-primary;
        border-radius: 100%;
        aspect-ratio: 1;
        padding: 0;
        min-width: 40px;
        min-height: 40px;

        span {
            position: relative;
            -webkit-transform: scale(1.3) !important;
            transform: scale(1.3) !important;

            @-moz-document url-prefix() {
                top: -0.05rem;
            }
        }
    }
}

.button-disabled {
    background-color: $color-light-grey!important;
    cursor: not-allowed;
    color: grey!important;
}

// tool tips
/*-----------------------------------------------------------------------------------*/

// tool tips-live outside of their assigned component.... so yeah -.-
.cdk-overlay-pane {  
    &.mat-mdc-tooltip-panel {  
        .mat-mdc-tooltip {
            color: white;
            background-color: $color-primary;
            border-radius: 2px;
            font-size: .9rem;
            padding: 5px;
        }
    } 

    .mdc-tooltip__surface,
    .mdc-tooltip__surface-animation {
        background-color: $color-primary!important;
        line-height: 1rem!important;
    }
}

// expansion panels
/*-----------------------------------------------------------------------------------*/

.mat-expansion-panel {
    box-shadow: none!important;
    margin-bottom: 20px;
    background-color: transparent;

    p {
        // font-size: $medium-p-font-size;
        font-weight: 400;
        margin: 0;
    }

    .mat-expansion-panel-header {
        
        padding: 0;

        p {
            color: $color-primary;
            font-weight: 400;
            margin: 0;
        }

        .mat-expansion-indicator::after {
            color: $color-primary; // Sets the color to red
        }

        .mat-expansion-panel-header-title {
            color: $color-primary;
        }

        &.mat-expanded {
            height: 48px;
        }

        .mat-content {
            flex: none;
        }

        .mat-expansion-indicator::after {
            position: relative;
            top: -3px;
        }
    }

    .mat-expansion-panel-body {
        padding: 0!important;

        p {
            line-height: 1.75em!important;
        }
    }

    .mat-expansion-panel-content {
        padding: 0;
    }
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]),
.mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]),
.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
    background: none;
}


// FLEX
/*-----------------------------------------------------------------------------------*/

// base flex
.flex { display: flex }
.grid { display: grid }
.flex-row { display: flex; flex-direction: row }
.flex-col { display: flex; flex-direction: column }

// flex-wrap utils
.flex-wrap { flex-wrap: wrap }  // Mostly used with flex-row, when wrapping is desired.
.flex-col-xs { @media screen and (max-width: 599px) { flex-direction: column } }  // Switch from flex-row to flex-col on mobile.

// gaps
.gap-4 { gap: 4px }
.gap-8 { gap: 8px }
.gap-10 { gap: 10px }
.gap-12 { gap: 12px }
.gap-16 { gap: 16px }

// justifying content
.space-between { justify-content: space-between } 
.justify-center { justify-content: center }  
.space-evenly { justify-content: space-evenly }  
.justify-end { justify-content: end }  
.justify-start { justify-content: start }  

// center items
.items-center { align-items: center }

// flex grow
.flex-1 { flex: 1 }  // same as flex: 1 1 0 (grow, shrink, basis 0) - has similar effect to width: 100%
.flex-grow { flex-grow: 1 }  // same as flex: 1 1 auto (grow, shrink, basis auto) - For spacer, etc.

// flex fill
.flex-fill {
  width: 100%;
  height: 100%;
  flex: 1 1 auto;
  min-width: 100%;
  min-height: 100%;
}

// hide & show for different breakpointsh
.hide-xs    { @media screen and (max-width: 599px) { display: none } }  // Hide on mobile.
.hide-gt-xs { @media screen and (min-width: 600px) { display: none } }  // Show only on mobile. Hide on desktop.
.hide-sm    { @media screen and (max-width: 959px) { display: none } }  // Hide on mobile/tablet.
.hide-gt-sm { @media screen and (min-width: 960px) { display: none } }  // Show only on mobile/tablet. Hide on desktop.
