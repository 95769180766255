
/***********************************************************************************************
theme colors
***********************************************************************************************/

$color-primary: #C1002B;

/***********************************************************************************************
theme tones
***********************************************************************************************/

$color-black: rgb(12, 12, 12);
$color-grey: #646363;
$color-light-grey: #e7e4e4;
$color-ultra-light-grey: #fafafa;
$color-dark-grey: #525151;

/***********************************************************************************************
font sizes
***********************************************************************************************/

$default-h1-font-size: 2rem;
$medium-h1-font-size: 1.75rem;
$small-h1-font-size: 1.5rem;

$small-h2-font-size: 0.875rem;
$medium-h2-font-size: 1rem;
$default-h2-font-size: 1.125rem;

$small-p-font-size: 0.75rem;
$medium-p-font-size: 0.875rem;
$default-p-font-size: 1rem;

$small-caption-font-size: 0.625rem;
$medium-caption-font-size: 0.6875rem;
$default-caption-font-size: 0.75rem;

/***********************************************************************************************
content width
***********************************************************************************************/

$default-content-width: 80vw;
$default-content-max-width: 900px;
$default-content-width-mobile: 95vw;
